import loadable from '@loadable/component';
import { LoginCallback } from '@okta/okta-react';
import RouteErrorBoundary from 'boundaries/RouteErrorBoundary';
import AppRoute from 'components/App/AppRoute';
import { withCompanySettingFeature, withUserResource } from 'hocs';
import { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const InactiveUserErrorView = loadable(() => import('views/InactiveUserErrorView'));
const ActivityFeedView = loadable(() => import('views/ActivityFeedView'));
const CompanyView = loadable(() => import('views/CompanyView'));
const CompanyJobDetailsView = loadable(() => import('views/CompanyJobDetailsView'));
const CompanySettingsView = loadable(() => import('views/CompanySettingsView'));
const ComplianceSettingsView = loadable(() => import('views/ComplianceSettingsView'));
const UserSettingsView = loadable(() => import('views/UserSettingsView'));
const DashboardView = loadable(() => import('views/DashboardView'));
const LoginView = loadable(() => import('views/LoginView'));
const JobCreateView = loadable(() => import('views/JobCreateView'));
const JobDetailsView = loadable(() => import('views/JobDetailsView'));
const JobFeedbackView = loadable(() => import('views/JobFeedbackView'));
const JobListView = loadable(() => import('views/JobListView'));
const JobPublicView = loadable(() => import('views/JobPublicView'));
const JobUpdateView = loadable(() => import('views/JobUpdateView'));
const ProfileCreateView = loadable(() => import('views/ProfileCreateView'));
const ProfileView = loadable(() => import('views/ProfileView'));
const ProfileFeedbackView = loadable(() => import('views/ProfileFeedbackView'));
const ProfileConsentView = loadable(() => import('views/ProfileConsentView'));
const ProfileListView = loadable(() => import('views/ProfileListView'));
const ProfileUpdateView = loadable(() => import('views/ProfileUpdateView'));
const ProfileUploadView = loadable(() => import('views/ProfileUploadView'));
const MessageListView = loadable(() => import('views/MessageListView'));
const NotificationListView = loadable(() => import('views/NotificationListView'));
const PrivacyPolicyView = loadable(() => import('views/PrivacyPolicyView'));
const TermsAndConditionsView = loadable(() => import('views/TermsAndConditionsView'));
const ErrorView = loadable(() => import('views/ErrorView'));
const ExternalSearchView = loadable(() => import('views/ExternalSearchView/ExternalSearchView'));
const CaslConfirmConsentView = loadable(() => import('views/CaslConfirmConsentView'));
const UserManagementView = loadable(() => import('views/UserManagementView'));
const ActionManagementView = loadable(() => import('views/ActionManagementView'));
const CustomRolesView = loadable(() => import('views/CustomRolesView'));
const UserInterviewView = loadable(() => import('views/UserInterviewView'));
const UserOffersView = loadable(() => import('views/UserOffersView'));

// Reports
const ReportsLibraryView = loadable(() => import('views/ReportsLibraryView/ReportsLibraryView'));
const ReportView = loadable(() => import('views/ReportsLibraryView/ReportView'));

// Talent Pools
const TalentPoolView = loadable(() => import('views/TalentPoolView'));

export interface AppRouterProps {}

const AppRouter = () => {
  return (
    <RouteErrorBoundary>
      <Switch>
        {/* Dashboard */}
        <AppRoute path="/" component={DashboardView} secure exact />
        {/* Auth */}
        <AppRoute path="/login" component={LoginView} exact />
        {/* Settings */}
        <Redirect path="/c/settings" to="/company/settings" exact />
        <Redirect path="/u/settings" to="/user/settings" exact />
        <AppRoute path="/company/settings" component={CompanySettingsView} secure exact />
        <AppRoute path="/user/settings" component={UserSettingsView} secure exact />
        <AppRoute path="/compliance/settings" component={ComplianceSettingsView} secure exact />
        <AppRoute path="/user-management" component={UserManagementView} secure exact />
        <AppRoute
          path="/action-management"
          component={withCompanySettingFeature(ActionManagementView, 'actionManagementFlag')}
          secure
          exact
        />
        <AppRoute path="/user-management/roles" component={CustomRolesView} secure exact />
        {/* Company */}
        <AppRoute path="/c/:slug" component={CompanyView} exact />
        <AppRoute path="/c/:slug/w/:hash" component={CompanyJobDetailsView} exact />
        {/* Work */}
        <Redirect path="/w" to="/work" exact />
        <Redirect path="/w/create" to="/work/create" exact />
        <Redirect path="/w/:hash/update" to="/work/:hash/update" exact />
        <Redirect path="/w/:hash/details" to="/work/:hash/details" exact />
        <AppRoute path="/work" component={JobListView} secure exact />
        <AppRoute path="/work/create" component={JobCreateView} secure exact />
        <AppRoute path="/work/:hash/update" component={JobUpdateView} secure exact />
        <AppRoute path="/w/:hash/feedback" component={JobFeedbackView} exact />
        <AppRoute path="/work/:hash" component={withUserResource(JobDetailsView)} secure />
        <AppRoute path="/w/:hash" component={JobPublicView} exact />
        {/* Profiles */}
        <Redirect path="/p" to="/profiles" exact />
        <Redirect path="/p/create" to="/profiles/create" exact />
        <Redirect path="/p/upload" to="/profiles/upload" exact />
        <Redirect path="/p/:hash" to="/profiles/:hash" exact />
        <Redirect path="/p/:hash/details" to="/profiles/:hash" exact />
        <Redirect path="/p/:hash/update" to="/profiles/:hash/update" exact />
        <AppRoute path="/profiles" component={ProfileListView} secure exact />
        <AppRoute path="/profiles/create" component={ProfileCreateView} secure exact />
        <AppRoute path="/profiles/upload" component={ProfileUploadView} secure exact />
        <AppRoute path="/profiles/:hash/update" component={ProfileUpdateView} secure exact />
        <AppRoute path="/offer-manager/:hash" component={UserOffersView} secure exact />
        <AppRoute path="/interview-manager/:hash" component={UserInterviewView} secure exact />
        <AppRoute path="/profiles/:hash" component={withUserResource(ProfileView)} secure />
        <AppRoute path="/p/:hash/feedback" component={ProfileFeedbackView} exact />
        <AppRoute path="/p/:hash/consent" component={ProfileConsentView} exact />
        <AppRoute path="/p/:hash/casl/confirm-consent" component={CaslConfirmConsentView} exact />
        {/* Messages */}
        <Redirect path="/m" to="/messages" exact />
        <AppRoute path="/messages" component={withUserResource(MessageListView)} secure exact />
        {/* Activity Feed */}
        <Redirect path="/af" to="/activity" exact />
        <AppRoute path="/activity" component={ActivityFeedView} secure exact />
        {/* Talent Pool */}
        <AppRoute path="/talent-pool" component={TalentPoolView} exact />
        {/* Notifications */}
        <Redirect path="/n" to="/notifications" exact />
        <AppRoute path="/notifications" component={withUserResource(NotificationListView)} secure exact />
        {/* {config.app.enableExternalSearch ? (
          <> */}
        <Redirect path="/s/external" to="/search/external" exact />
        <AppRoute path="/search/external" component={ExternalSearchView} secure />
        {/* </>
        ) : null} */}
        {/* Okta */}
        <Route exact path="/implicit/callback" component={LoginCallback} />
        {/* Privacy & Terms */}
        <AppRoute path="/privacy" component={PrivacyPolicyView} exact />
        <AppRoute path="/terms" component={TermsAndConditionsView} exact />
        {/* Reports */}
        <AppRoute path="/reports-library" component={ReportsLibraryView} exact />
        <AppRoute path="/reports/:hash" component={ReportView} secure exact />
        {/* Error */}
        <AppRoute path="/inactive-user-login" component={InactiveUserErrorView} exact />
        <AppRoute component={ErrorView} />
      </Switch>
    </RouteErrorBoundary>
  );
};

export default memo(AppRouter);
